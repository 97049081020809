import { store } from '@/store'

export default class Model {
	/**
	 * @param {?object} obj
	 */
	constructor (obj = null) {
		if (obj) {
			Object.entries(obj).forEach(([key, value]) => {
				this[key] = value
			})
		}
	}

	/**
	 * @return {boolean}
	 */
	get _is_accountant_admin () {
		return store.state.accountingFirm.selected.isAccountantAdmin ?? false
	}

	/**
	 * @return {boolean}
	 */
	get is_saved () {
		return !!this.id
	}

	/**
	 * @return {Model}
	 */
	clone () {
		return new this.constructor(this.toObject())
	}

	/**
	 * @return {object}
	 */
	forApi () {
		const apiFields = this.apiFields

		let result = {}

		apiFields.forEach(key => {
			if (this[key] !== undefined) {
				result[key] = this[key]
			}
		})

		return result
	}

	/**
	 * @param {object = {}} additionalData
	 *
	 * @return {object}
	 */
	toObject (additionalData = {}) {
		let object = {}

		Object.keys(this).forEach(key => {
			object[key] = copyItem(this[key])
		})

		const prototype = Object.getPrototypeOf(this)
		const descriptors = Object.getOwnPropertyDescriptors(prototype)

		Object.entries(descriptors).forEach(([key, descriptor]) => {
			if (!descriptor.get || !descriptor.set) {
				return
			}
			object[key] = copyItem(this[key])
		})

		return Object.assign(object, additionalData)
	}

	/**
	 * @param {object|Model} newData
	 *
	 * @return void
	 */
	update (newData) {
		if (Model.prototype.isPrototypeOf(newData)) {
			newData = newData.toObject()
		}

		Object.entries(newData).forEach(([key, value]) => {
			this._updateField(key, value)
		})
	}

	_updateField (key, value) {
		this[key] = value
	}
}

const copyItem = item => {
	if (Array.isArray(item)) {
		return item.map(i => copyItem(i))
	} else if (item !== null && typeof item === 'object' && Model.prototype.isPrototypeOf(item)) {
		return item.toObject()
	} else if (typeof item === 'object' && item !== null) {
		let newItem = {}

		Object.keys(item).forEach(key => {
			newItem[key] = copyItem(item[key])
		})

		return newItem
	}

	return item
}
