import Duration from '@/classes/Duration'
import Model from '@/classes/Model'
import Category from '@/classes/Offers/Category'
import Service from '@/classes/Offers/Service'

export default class CatalogService extends Model {
	#category
	#task_creation_date_unit

	/**
	 * @param {object} catalogService
	 * @param {number} catalogService.id
	 * @param {object} catalogService.category
	 * @param {string} catalogService.commercial_description
	 * @param {string} catalogService.contractual_description
	 * @param {string} catalogService.name
	 * @param {number} catalogService.price
	 * @param {string} catalogService.unique_reference
	 */
	constructor (catalogService = {}) {
		const category = catalogService.category
		delete catalogService.category

		const taskCreationDateUnit = catalogService.task_creation_date_unit
		delete catalogService.task_creation_date_unit

		super(catalogService)

		if (category && Category.prototype.isPrototypeOf(category)) {
			this.#category = category
		} else if (category) {
			this.#category = new Category(category)
		}
		if (taskCreationDateUnit) {
			this.task_creation_date_unit = taskCreationDateUnit
		}
	}

	/**
	 * @return {array}
	 */
	get apiFields () {
		return [
			'deliverable_id',
			'commercial_description',
			'contractual_description',
			'is_private',
			'name',
			'price',
			'task_creation_date_unit_id',
			'task_creation_date_value',
			'task_models',
			'time_allocation'
		]
	}

	/**
	 * @return {?Category}
	 */
	get category () {
		return this.#category
	}

	/**
	 * @param {?number}
	 */
	get category_id () {
		return this.#category.id
	}

	/**
	 * @return {?number}
	 */
	get deliverable_id () {
		return this.deliverable?.id
	}

	/**
	 * @return {boolean}
	 */
	get is_archived () {
		return !!this.deleted_at
	}

	/**
	 * @return {?Duration}
	 */
	get task_creation_date_unit () {
		return this.#task_creation_date_unit
	}

	/**
	 * @return {?number}
	 */
	get task_creation_date_unit_id () {
		return this.task_creation_date_unit?.id
	}

	/**
	 * @return {string}
	 */
	get title () {
		return `${this.unique_reference} - ${this.name}`
	}

	set task_creation_date_unit (duration) {
		if (duration && Duration.prototype.isPrototypeOf(duration)) {
			this.#task_creation_date_unit = duration
		} else if (duration) {
			this.#task_creation_date_unit = new Duration(duration)
		}
	}

	/**
	 * @param {?object} additionalData
	 *
	 * @return {Service}
	 */
	instanciate (additionalData) {
		if (!additionalData) {
			additionalData = {}
		}

		return new Service({
			catalog_service: this,
			...additionalData
		})
	}

	toObject (additionalData = {}) {
		additionalData.category = this.#category

		return super.toObject(additionalData)
	}
}
