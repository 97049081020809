import Model from '@/classes/Model'
import CatalogService from '@/classes/Offers/CatalogService'
export default class Category extends Model {
	/**
	 * @param {object} category
	 * @param {number} category.id
	 * @param {string} category.abbreviation
	 * @param {string} category.color
	 * @param {string} category.name
	 */
	constructor (category) {
		const catalogServices = category.catalog_services
		delete category.catalog_services

		super(category)

		if (Array.isArray(catalogServices)) {
			this.catalog_services = catalogServices.map(cs => new CatalogService(cs))
		}
	}
}
