import Model from '@/classes/Model'

export default class Duration extends Model {
	/**
	 * @param {object} duration
	 * @param {number} duration.id
	 * @param {string} duration.name
	 */
	constructor (duration) {
		super(duration)
	}
}
