import Model from '@/classes/Model'
import FrequencyEnding from '@/classes/Offers/FrequencyEnding'
import { i18n } from '@/plugins/i18n'
export default class Frequency extends Model {
	/**
	 * @param {object} frequency
	 * @param {number} frequency.id
	 * @param {string} frequency.key
	 */
	constructor (frequency) {
		const endings = frequency.endings
		delete frequency.endings

		super(frequency)

		if (Array.isArray(endings)) {
			this.endings = endings.map(e => new FrequencyEnding(e))
		}

		this.name = i18n.t(`projects.frequencies.${this.key}`)
	}
}
