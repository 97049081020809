import Model from '@/classes/Model'

export default class FrequencyEnding extends Model {
	/**
	 * @param {object} frequencyEnding
	 */
	constructor (frequencyEnding) {
		super(frequencyEnding)
	}
}
