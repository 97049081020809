import Model from '@/classes/Model'
import CatalogService from '@/classes/Offers/CatalogService'
import Frequency from '@/classes/Offers/Frequency'
import FrequencyEnding from '@/classes/Offers/FrequencyEnding'
import Project from '@/classes/Offers/Project'

export default class Service extends Model {
	#frequency
	#frequency_ending
	#project

	/**
	 * @param {object} service
	 * @param {?number} service.id
	 * @param {object} service.catalog_service
	 * @param {object} service.frequency
	 */
	constructor (service) {
		const catalogService = new CatalogService(service.catalog_service)
		delete service.catalog_service

		const frequency = service.frequency
		delete service.frequency

		const project = service.project
		delete service.project

		super(service)

		this.catalog_service = catalogService
		if (frequency) {
			this.frequency = frequency
		}
		if (Project.prototype.isPrototypeOf(project)) {
			this.#project = project
		} else if (project) {
			this.#project = new Project(project)
		}
	}

	/**
	 * @return {Array<string>}
	 */
	get apiFields () {
		let defaultArray = ['frequency_id', 'frequency_ending_id', 'frequency_ending_value', 'frequency_ending_first', 'price', 'time_allocation']

		if (!this.is_saved) {
			defaultArray.push('catalog_offer_id', 'catalog_service_id', 'project_id', 'start', 'end')
		}
		return defaultArray
	}

	/**
	 * @return {boolean}
	 */
	get can_delete () {
		return this.#project.status.key === 'not-signed'
	}

	/**
	 * @return {boolean}
	 */
	get can_update () {
		return this.#project.status.key === 'not-signed'
	}

	/**
	 * @return {Number}
	 */
	get catalog_service_id () {
		return this.catalog_service.id
	}

	/**
	 * @return {Category}
	 */
	get category () {
		return this.catalog_service.category
	}

	/**
	 * @return {*}
	 */
	get category_id () {
		return this.catalog_service.category_id
	}

	/**
	 * @return {?Frequency}
	 */
	get frequency () {
		return this.#frequency
	}

	/**
	 * @param {object|Frequency} frequency
	 */
	set frequency (frequency) {
		if (Frequency.prototype.isPrototypeOf(frequency)) {
			this.#frequency = frequency
		} else if (typeof frequency === 'object') {
			this.#frequency = new Frequency(frequency)
		}
	}

	/**
	 * @return {?FrequencyEnding}
	 */
	get frequency_ending () {
		return this.#frequency_ending
	}

	/**
	 * @param {object|FrequencyEnding} newVal
	 */
	set frequency_ending (newVal) {
		if (FrequencyEnding.prototype.isPrototypeOf(newVal)) {
			this.#frequency_ending = newVal
		} else if (newVal) {
			this.#frequency_ending = new FrequencyEnding(newVal)
		}
	}

	/**
	 * @return {?number}
	 */
	get frequency_ending_id () {
		return this.#frequency_ending?.id
	}

	/**
	 * @return {?number}
	 */
	get frequency_id () {
		return this.#frequency?.id
	}

	/**
	 * @return {boolean}
	 */
	get is_renewable () {
		return !this.catalog_service.is_archived
	}

	/**
	 * @return {string}
	 */
	get name () {
		return this.catalog_service.name
	}

	/**
	 * @return {?Project}
	 */
	get project () {
		return this.#project
	}

	/**
	 * @return {?number}
	 */
	get project_id () {
		if (!this.#project) {
			return undefined
		}
		return this.#project.id
	}

	/**
	 * @return {string}
	 */
	get title () {
		return this.catalog_service.title
	}

	/**
	 * @return {string}
	 */
	get unique_reference () {
		return this.catalog_service.unique_reference
	}

	/**
	 * @param {string} field
	 * @param {any} value
	 */
	_updateField (field, value) {
		switch (field) {
			case 'catalog_service':
				if (CatalogService.prototype.isPrototypeOf(value)) {
					this.catalog_service = value
				} else {
					this.catalog_service = new CatalogService(value)
				}
				break
			case 'frequency':
				this.#frequency = value
				break
			case 'frequency_ending':
				this.#frequency_ending = value
				break
			case 'project':
				this.#project = value
				break
			default:
				this[field] = value
				break
		}
	}
}
