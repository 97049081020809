import Model from '@/classes/Model'
import Service from '@/classes/Offers/Service'
import Status from '@/classes/Offers/Status'
import moment from '@/plugins/moment'

const MOMENT_FORMAT = 'YYYY-MM-DD'
const SignedStatus = 'signed'
const NotSignedStatus = 'not-signed'
export default class Project extends Model {
	#is_renewable
	#status
	/**
	 * @param {object} project
	 */
	// eslint-disable-next-line space-before-function-paren
	constructor(project = {}) {
		const isRenewable = project.is_renewable
		delete project.is_renewable

		const services = project.services
		delete project.services

		const status = project.status
		delete project.status

		super(project)

		if (Array.isArray(services)) {
			this.services = services.map(s => new Service({ ...s, project: this }))
		} else {
			this.services = []
		}
		if (status) {
			this.#status = new Status(status)
		}

		this.#is_renewable = isRenewable
	}

	/**
	 * @return {boolean}
	 */
	get are_dates_of_services_valid () {
		const startDate = moment(this.start, MOMENT_FORMAT)
		const endDate = moment(this.end, MOMENT_FORMAT)

		return this.services.every(service => {
			const serviceEndDate = moment(service.end, MOMENT_FORMAT)
			const serviceStartDate = moment(service.start, MOMENT_FORMAT)

			return (
				serviceStartDate.isSameOrAfter(startDate, 'day') &&
				serviceStartDate.isBefore(endDate, 'day') &&
				serviceEndDate.isSameOrBefore(endDate, 'day') &&
				serviceEndDate.isAfter(startDate, 'day')
			)
		})
	}

	/**
	 * @return {boolean}
	 */
	get can_change_dates () {
		return this._is_accountant_admin && this.status.key === NotSignedStatus
	}

	/**
	 * @return {boolean}
	 */
	get can_change_is_renewable () {
		if (this.status.key === 'done' || !this._is_accountant_admin) {
			return false
		}
		if (this.status.key === NotSignedStatus) {
			return true
		}
		const currentDate = moment()
		const endDate = moment(this.end, MOMENT_FORMAT)

		return endDate.isAfter(currentDate, 'day')
	}

	/**
	 * Check if the connected user can CREATE / DELETE / UPDATE a service on this project
	 *
	 * @return {boolean}
	 */
	get can_change_services () {
		return this._is_accountant_admin && this.status.key === NotSignedStatus
	}

	/**
	 * @return {boolean}
	 */
	get can_change_status () {
		return this._is_accountant_admin
	}

	/**
	 * @return {boolean}
	 */
	get can_create_tasks () {
		return this.status.key === SignedStatus
	}

	/**
	 * @return {boolean}
	 */
	get can_delete () {
		if (!this._is_accountant_admin) {
			return false
		}
		return this.status.key === NotSignedStatus
	}

	/**
	 * @return {boolean}
	 */
	get can_generate_engagement_letter () {
		return this._is_accountant_admin && this.status.key === NotSignedStatus
	}

	/**
	 * @return {boolean}
	 */
	get can_rename () {
		return this._is_accountant_admin
	}

	/**
	 * @return {Array<Category>}
	 */
	get categories () {
		let result = []
		const uniqueCategoryIds = new Set()

		this.services.forEach(service => {
			const category = service.category
			if (uniqueCategoryIds.has(category.id)) {
				const categoryIndex = result.findIndex(c => c.id === category.id)
				result[categoryIndex].count++
			} else {
				uniqueCategoryIds.add(category.id)
				category.count = 1
				result.push(category)
			}
		})

		return result
	}

	/**
	 * @return {boolean}
	 */
	get contains_non_renewable_services () {
		return this.services.some(s => !s.is_renewable)
	}

	/**
	 * @return {boolean}
	 */
	get is_renewable () {
		return this.#is_renewable
	}

	/**
	 * @param {boolean} isRenewable
	 *
	 * @throws {Error}
	 */
	set is_renewable (isRenewable) {
		if (this.can_change_is_renewable) {
			this.#is_renewable = isRenewable
		} else {
			throw new Error('Cannot change "is_renewable" property')
		}
	}

	/**
	 * @return {boolean}
	 */
	get is_valid () {
		if (!this.name || !this.start || !this.end) {
			return false
		}

		const endDate = moment(this.end, MOMENT_FORMAT)
		const startDate = moment(this.start, MOMENT_FORMAT)

		return !endDate.isBefore(startDate, 'day')
	}

	/**
	 * @return {number}
	 */
	get price () {
		if (this.services.length === 0) {
			return 0
		}
		return this.services.reduce((acc, service) => acc + (service.price ?? 0), 0)
	}

	/**
	 * @return {Status}
	 */
	get status () {
		return this.#status
	}

	/**
	 * @param {Status} newStatus
	 */
	set status (newStatus) {
		this.status_id = newStatus.id

		this.#status = newStatus
	}

	_updateField (field, value) {
		if (field === 'is_renewable') {
			this.#is_renewable = value
		} else if (field === 'status') {
			this.#status = value
		} else if (field === 'services') {
			const services = value.map(s => {
				if (Service.prototype.isPrototypeOf(s)) {
					return s
				} else {
					return new Service(s)
				}
			})
			services.forEach(s => {
				const serviceIndex = this.services.findIndex(service => s.id === service.id)

				if (serviceIndex === -1) {
					this.services.push(s)
				} else {
					this.services[serviceIndex].update(s)
				}
			})
		} else {
			this[field] = value
		}
	}
}
